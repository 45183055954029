import {Machine} from "xstate";

export const ImageUploadMachine = Machine({
    id: 'image-upload-machine',
    initial: 'IDLE',
    states: {
        IDLE: {
            type: 'atomic',
            on: {
                PROCESS: 'PROCESSING',
            },
        },
        PROCESSING: {
            type: 'atomic',
            on: {
                UPLOAD: 'UPLOADING',
                FAIL: 'FAILURE',
                RESET: 'IDLE',
            },
        },
        UPLOADING: {
            type: 'atomic',
            on: {
                SUCCEED: 'SUCCESS',
                FAIL: 'FAILURE',
            },
        },
        SUCCESS: {
            type: 'atomic',
            after: {
                2000: 'IDLE',
            },
        },
        FAILURE: {
            type: 'atomic',
            after: {
                2000: 'IDLE',
            },
        },
    },
});
